"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _globals = _interopRequireDefault(require("./globals"));

var _includes = _interopRequireDefault(require("lodash/includes"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* global Raven */
var _default = {
  notify: function notify(message, severity, extras) {
    var skipProd = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    // Until we do a bigger rewrite of client-side logging with proper releases and npm-ing, just use Raven globally.
    var isDev = false;
    var isNotProd = false;

    if (typeof window !== "undefined") {
      var currentEnv = _globals.default.get("environment");

      isDev = (0, _includes.default)(["LOCAL", "TESTING", "DEVELOPMENT"], currentEnv);
      isNotProd = (0, _includes.default)(["LOCAL", "TESTING", "DEVELOPMENT", "STAGING"], currentEnv);
    }

    if (typeof Raven !== "undefined") {
      var options = {
        level: severity,
        tags: {
          site: "www"
        }
      };

      if (extras) {
        options.extra = extras;
      }

      if (!skipProd || isNotProd) {
        Raven.captureMessage(message, options);
      }
    } // log to console on development environments


    if (isDev) {
      var logMethod;

      switch (severity) {
        case "warning":
          logMethod = window.console.warn;
          break;

        case "error":
          logMethod = window.console.error;
          break;

        default:
          logMethod = window.console.log;
      }

      logMethod(message, extras);
    }
  },
  setSessionId: function setSessionId(sessionId) {
    if (typeof Raven !== "undefined") {
      Raven.setTagsContext({
        SessionId: sessionId
      });
    }
  },
  setUserId: function setUserId(userId) {
    if (typeof Raven !== "undefined") {
      Raven.setTagsContext({
        UserId: userId
      });
    }
  }
};
exports.default = _default;