"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

var _findIndex = _interopRequireDefault(require("lodash/findIndex"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _default(list, object, comparator) {
  var index = (0, _findIndex.default)(list, comparator);

  if (index > -1) {
    list.splice(index, 1, object);
  } else {
    list.push(object);
  }

  return list;
}