"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.appendQueryParams = appendQueryParams;

var _queryString = _interopRequireDefault(require("query-string"));

var _extend = _interopRequireDefault(require("lodash/extend"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function appendQueryParams(url, query) {
  var urlParts = url.split("?");
  var newQueryParams = typeof query == "string" ? _queryString.default.parse(query) : query;

  if (urlParts.length == 2) {
    var existingQueryParams = _queryString.default.parse(urlParts[1]);

    return urlParts[0] + "?" + _queryString.default.stringify((0, _extend.default)({}, existingQueryParams, newQueryParams));
  } else if (urlParts.length == 1) {
    return urlParts[0] + "?" + _queryString.default.stringify(newQueryParams);
  } else {
    throw new Error("Invalid URL");
  }
}