"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defs = exports.default = void 0;

var _formatters = require("./formatters");

var _extend = _interopRequireDefault(require("lodash/extend"));

var _validator = _interopRequireDefault(require("validator"));

var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));

var _isUndefined = _interopRequireDefault(require("lodash/isUndefined"));

var _isNaN = _interopRequireDefault(require("lodash/isNaN"));

var _isNull = _interopRequireDefault(require("lodash/isNull"));

var _isString = _interopRequireDefault(require("lodash/isString"));

var _stringFormat = _interopRequireDefault(require("string-format"));

var _includes = _interopRequireDefault(require("lodash/includes"));

var _mapValues = _interopRequireDefault(require("lodash/mapValues"));

var _moment = _interopRequireDefault(require("moment"));

var _isEmptyGuid = _interopRequireDefault(require("./isEmptyGuid"));

var _forEach = _interopRequireDefault(require("lodash/forEach"));

var _split = _interopRequireDefault(require("lodash/split"));

var _trim = _interopRequireDefault(require("lodash/trim"));

var _endsWith = _interopRequireDefault(require("lodash/endsWith"));

var _toLower = _interopRequireDefault(require("lodash/toLower"));

var _formConverters = _interopRequireDefault(require("./formConverters"));

var _isNil = _interopRequireDefault(require("lodash/isNil"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
var dateShortRegex = /^\d{2}\/\d{4}$/;
var dateTwoDigitShortRegex = /^\d{1,2}\/\d{4}$/;
var dateTwoDigitExtraShortRegex = /^\d{1,2}\/\d{2}$/;
var dateTimeRegex = /^\d{4}\-\d{2}\-\d{2}T\d{2}:\d{2}:\d{2}((\.\d{3}Z)|(\-|\+)\d{2}:\d{2})?$/;
var zipCodeRegex = /(^\d{5}$)|(^\d{5}\-\d{4}$)/;
var specialCharacterRegex = /[`~!@#$%^&*()\-=[\]\\;',./_+{}|:"<>?]/;
var excludedCharacterRegex = /^((?=[A-Za-z\d`~!@#$%^&*()\-=[\]\\;',./_+{}|:"<>?]).)*$/;
var NumericalMinimum = 9e-7;
var convertPercentWithSign = _formConverters.default.out.convertPercentWithSign;

var requiredHelper = function requiredHelper(key, value, messageTemplate) {
  if ((0, _isUndefined.default)(value) || (0, _isNaN.default)(value) || (0, _isNull.default)(value) || (0, _isEmpty.default)(value.toString())) {
    return (0, _stringFormat.default)(messageTemplate, key);
  }

  return null;
};

var isInvalidEmail = function isInvalidEmail(email) {
  return !(0, _isEmpty.default)(email) && !_validator.default.isEmail(email);
};

var _getMoment = function _getMoment(value) {
  if (_validator.default.matches(value, dateTwoDigitExtraShortRegex)) {
    return new _moment.default(value, _formatters.MOMENT_FORMAT_MONTH_YEAR_SHORT);
  }

  if (_validator.default.matches(value, dateTwoDigitShortRegex)) {
    return new _moment.default(value, _formatters.MOMENT_FORMAT_DATE_SHORT);
  }

  if (_validator.default.matches(value, dateRegex) || _validator.default.matches(value, dateTimeRegex)) {
    return new _moment.default(value);
  }

  return null;
};

var isNumberOutOfAcceptableRange = function isNumberOutOfAcceptableRange(value) {
  if (value && value > Number.MAX_SAFE_INTEGER) {
    return "This number is too large";
  } // Numbers smaller than this are converted to exponential notation and validator does
  // not work with numbers in exponential notation. https://github.com/validatorjs/validator.js/issues/1309
  // Consider updating once that issue is resolved. For now, product confirmed that numbers of
  // this size should not be accepted anywhere in the site.


  if (value && value > 0 && value <= NumericalMinimum) {
    return "This number is too small";
  }

  if (value < 0) {
    return "Number cannot be negative";
  }

  return null;
}; // All of our supported validations, with pretty messages (or at least support to pass in pretty messages).


var defs = {
  isMemberOf: function isMemberOf(values, message, value) {
    if (!(0, _includes.default)(values, value)) {
      return message;
    }

    return null;
  },
  isNotMemberOf: function isNotMemberOf(values, message, value) {
    if ((0, _includes.default)(values, value)) {
      return message;
    }

    return null;
  },
  isRequired: function isRequired(key, value) {
    return requiredHelper(key, value, "{0} is required");
  },
  areRequired: function areRequired(key, value) {
    return requiredHelper(key, value, "{0} are required");
  },
  isNonEmptyGuid: function isNonEmptyGuid(key, value) {
    if ((0, _isEmptyGuid.default)(value.toString())) {
      return (0, _stringFormat.default)("{0} is required", key);
    }

    return null;
  },
  isGuid: function isGuid(value) {
    if (!(0, _isEmpty.default)(value) && !_validator.default.isUUID(value)) {
      return "This isn't a valid GUID";
    }

    return null;
  },
  isEmail: function isEmail(value) {
    if (isInvalidEmail(value)) {
      return "This isn't a valid email address";
    }

    return null;
  },
  isDomainName: function isDomainName(value) {
    if (!(0, _isEmpty.default)(value) && !_validator.default.isFQDN(value)) {
      return "This isn't a valid domain name";
    }

    return null;
  },
  // This is part of our strategy to prevent ourselves from adding non-gradifi emails to our test lists in Responsys
  isEmailInternalDomain: function isEmailInternalDomain(value) {
    if ((0, _endsWith.default)((0, _toLower.default)(value), "@gradifi.com") || (0, _endsWith.default)((0, _toLower.default)(value), "@vestwell.com")) {
      return null;
    }

    return "Email address must end in either '@gradifi.com' or '@vestwell.com'";
  },
  isEmailList: function isEmailList(value) {
    var invalidEmailCount = 0;

    if ((0, _isEmpty.default)(value)) {
      return null;
    } else {
      (0, _forEach.default)((0, _split.default)(value, ","), function (email) {
        var trimmedEmail = (0, _trim.default)(email);

        if (isInvalidEmail(trimmedEmail)) {
          invalidEmailCount++;
        }
      });
    }

    if (invalidEmailCount > 0) {
      return " You have entered ".concat(invalidEmailCount, " invalid email ").concat(invalidEmailCount > 1 ? "addressses" : "address", ". ");
    } else {
      return null;
    }
  },
  isDate: function isDate(value) {
    if ((0, _isNil.default)(value)) {
      return null;
    }

    var invalidDateMessage = "This isn't a valid date. Use format MM/DD/YYYY";

    var dateRegexMatch = _validator.default.matches(value, dateRegex);

    if (!(0, _isEmpty.default)(value) && !dateRegexMatch && !_validator.default.matches(value, dateTimeRegex)) {
      return invalidDateMessage;
    }

    if (dateRegexMatch) {
      var date = (0, _moment.default)(value);

      if (!date.isValid()) {
        return invalidDateMessage;
      }
    }

    return null;
  },
  isValidShortDate: function isValidShortDate(value) {
    if (!(0, _isEmpty.default)(value) && !_validator.default.matches(value, dateShortRegex)) {
      return "This isn't a valid date. Use format MM/YYYY";
    }

    return null;
  },
  isValidShortOrExtraShortDate: function isValidShortOrExtraShortDate(value) {
    var date = "Invalid date";

    if (_validator.default.matches(value, dateTwoDigitExtraShortRegex)) {
      date = (0, _moment.default)(value, _formatters.MOMENT_FORMAT_MONTH_YEAR_SHORT).format(_formatters.MOMENT_FORMAT_DATE_SHORT).toString();
    } else if (_validator.default.matches(value, dateTwoDigitShortRegex)) {
      date = (0, _moment.default)(value, _formatters.MOMENT_FORMAT_DATE_SHORT).format(_formatters.MOMENT_FORMAT_DATE_SHORT).toString();
    }

    return date === "Invalid date" ? "This isn't a valid date." : null;
  },
  // The "now" parameter exists to support TimeWarp. Callers need to know what "now" is based on session data.
  isAgeOrOlderAsOf: function isAgeOrOlderAsOf(now, age, value) {
    if ((0, _moment.default)(now).diff(value, "years") < age) {
      return (0, _stringFormat.default)("Must be {0} years old or older.", age);
    }

    return null;
  },
  // The "now" parameter exists to support TimeWarp. Callers need to know what "now" is based on session data.
  isAgeOrYoungerAsOf: function isAgeOrYoungerAsOf(now, age, value) {
    if ((0, _moment.default)(now).diff(value, "years") > age) {
      return (0, _stringFormat.default)("Must be {0} years old or younger.", age);
    }

    return null;
  },
  // The "now" parameter exists to support TimeWarp. Callers need to know what "now" is based on session data.
  isDatePast: function isDatePast(now, value) {
    if (!(0, _isEmpty.default)(value) && !(0, _includes.default)((0, _moment.default)(value).from(now), "ago")) {
      return "This date is not in the past";
    }

    if (!(0, _isEmpty.default)(value) && (0, _moment.default)(value).isBefore(new Date(1900, 0, 1))) {
      return "Date must be after or equal to January 1st, 1900";
    }

    return null;
  },
  isDateFuture: function isDateFuture(message, now, value) {
    if (!(0, _isEmpty.default)(value) && !_getMoment(value).isAfter(now)) {
      return message || "This date is not in the future";
    }

    return null;
  },
  isDateWithinRange: function isDateWithinRange(minDate, maxDate, value) {
    var date = Date.parse(value);

    if (date >= minDate && date <= maxDate) {
      return null;
    }

    return "Date must be within range: ".concat(minDate.toLocaleDateString(), " - ").concat(maxDate.toLocaleDateString());
  },
  isDateWithinGradifiHistoryRange: function isDateWithinGradifiHistoryRange(value) {
    var minDate = new Date("1/1/2016");
    var maxDate = new Date("12/31/9999");
    var date = Date.parse(value);

    if (date >= minDate && date <= maxDate) {
      return null;
    }

    return "Date must be within range: ".concat(minDate.toLocaleDateString(), " - ").concat(maxDate.toLocaleDateString());
  },
  isPhoneNumber: function isPhoneNumber(value) {
    // validator.isMobilePhone does not cover all 10 digit combos
    if (!(0, _isEmpty.default)(value) && (value.match(/\d/g) || []).length !== 10) {
      return "This isn't a valid 10-digit phone number";
    }

    return null;
  },
  isNumeric: function isNumeric(value) {
    var outOfRangeMessage = isNumberOutOfAcceptableRange(value);

    if (!(0, _isNull.default)(outOfRangeMessage)) {
      return outOfRangeMessage;
    }

    if (value && !_validator.default.isNumeric(value)) {
      return "This must be a valid number";
    }

    return null;
  },
  isDecimal: function isDecimal(value) {
    var outOfRangeMessage = isNumberOutOfAcceptableRange(value);

    if (!(0, _isNull.default)(outOfRangeMessage)) {
      return outOfRangeMessage;
    }

    if (value && !_validator.default.isDecimal(value)) {
      return "This must be a valid number";
    }

    return null;
  },
  isDecimalIgnorePercentSign: function isDecimalIgnorePercentSign(value) {
    var cleanValue = convertPercentWithSign(value);
    var outOfRangeMessage = isNumberOutOfAcceptableRange(cleanValue);

    if (!(0, _isNull.default)(outOfRangeMessage)) {
      return outOfRangeMessage;
    }

    if (cleanValue && !_validator.default.isDecimal(cleanValue)) {
      return "This must be a valid number";
    }

    return null;
  },
  isInteger: function isInteger(value) {
    var outOfRangeMessage = isNumberOutOfAcceptableRange(value);

    if (!(0, _isNull.default)(outOfRangeMessage)) {
      return outOfRangeMessage;
    }

    if (value && !_validator.default.isInt(value)) {
      return "This must be a valid integer";
    }

    return null;
  },
  isGreaterThan: function isGreaterThan(message, min, value) {
    if (value && (!_validator.default.isFloat(value) || value <= min)) {
      return message;
    }

    return null;
  },
  isGreaterThanOrEqual: function isGreaterThanOrEqual(message, min, value) {
    if (value && (!_validator.default.isFloat(value) || value < min)) {
      return message;
    }

    return null;
  },
  isGreaterThanOrEqualWithPercentSign: function isGreaterThanOrEqualWithPercentSign(message, min, value) {
    var cleanValue = convertPercentWithSign(value);

    if (cleanValue && (!_validator.default.isFloat(cleanValue) || cleanValue < min)) {
      return message;
    }

    return null;
  },
  isLessThan: function isLessThan(key, max, value) {
    if (value && (!_validator.default.isFloat(value) || value >= max)) {
      return (0, _stringFormat.default)("{0} must be less than {1}", key, max);
    }

    return null;
  },
  isLessThanWithPercentSign: function isLessThanWithPercentSign(message, max, value) {
    var cleanValue = convertPercentWithSign(value);

    if (cleanValue && (!_validator.default.isFloat(cleanValue) || cleanValue >= max)) {
      return message;
    }

    return null;
  },
  isLessThanOrEqual: function isLessThanOrEqual(message, max, value) {
    if (value && (!_validator.default.isFloat(value) || value > max)) {
      return message;
    }

    return null;
  },
  isAlphanumeric: function isAlphanumeric(value) {
    if (!(0, _isNil.default)(value) && !/^[a-zA-Z0-9_\-]*$/.test(value)) {
      return (0, _stringFormat.default)("{0} is not alphanumeric", value);
    }

    return null;
  },
  hasTwoOrLessDecimalPlaces: function hasTwoOrLessDecimalPlaces(value) {
    if (!(0, _isNil.default)(value) && !_validator.default.matches(value, /^\d+(\.\d{1,2})?$/)) {
      return (0, _stringFormat.default)("{0} has more than 2 decimal places", value);
    }

    return null;
  },
  isChecked: function isChecked(message, value) {
    if ((0, _isString.default)(value)) {
      return value !== "true" ? message : null;
    } else if (!value) {
      return message;
    }

    return null;
  },
  isTin: function isTin(value) {
    if (!(0, _isNil.default)(value) && !_validator.default.matches(value, /^(\d{2}-\d{7})$/)) {
      return "TIN or FEIN must be in the following format: ##-#######";
    }

    return null;
  },
  isRoutingNumber: function isRoutingNumber(value) {
    if (!(0, _isNil.default)(value) && !_validator.default.matches(value, /^\d{9}$/)) {
      return "Routing number must be in the following format: #########";
    }

    return null;
  },
  isServicerCode: function isServicerCode(value) {
    if (!(0, _isNil.default)(value) && !_validator.default.matches(value, /^[A-Z]{4}\d{3}$/)) {
      return "Servicer code must be in the following format: AAAA###";
    }

    return null;
  },
  isErpCode: function isErpCode(value) {
    if (!(0, _isNil.default)(value) && !_validator.default.matches(value, /^[A-Z]{3}\d{3}$/)) {
      return "ERP code must be in the following format: AAA###";
    }

    return null;
  },
  isLengthLessThanOrEqual: function isLengthLessThanOrEqual(key, upperLength, value) {
    if (!(0, _isNil.default)(value) && !_validator.default.isLength(value, 0, upperLength)) {
      return (0, _stringFormat.default)("{0} must be no more than {1} characters", key, upperLength);
    }

    return null;
  },
  isZipCode: function isZipCode(value) {
    if (!(0, _isEmpty.default)(value) && !_validator.default.matches(value, zipCodeRegex)) {
      return "This isn't a valid U.S. zip code";
    }

    return null;
  },
  isLength: function isLength(key, length, value) {
    if (!(0, _isEmpty.default)(value) && !_validator.default.isLength(value, length)) {
      return (0, _stringFormat.default)("{0} must be at least {1} characters", key, length);
    }

    return null;
  },

  /*
   * Password validation function
   * Long and separated to be able to provide proper feedback
   */
  isPassword: function isPassword(value) {
    if ((0, _isNil.default)(value)) {
      return null;
    }

    if (!_validator.default.isLength(value, 10)) {
      return "Password must be at least 10 characters";
    }

    if (!_validator.default.matches(value, /[a-z]/)) {
      return "Password must contain at least one lowercase letter";
    }

    if (!_validator.default.matches(value, /[A-Z]/)) {
      return "Password must contain at least one uppercase letter";
    }

    if (!_validator.default.matches(value, /\d/)) {
      return "Password must contain at least one number";
    }

    if (!_validator.default.matches(value, specialCharacterRegex)) {
      return "Password must contain at least one special character";
    }

    if (!_validator.default.matches(value, excludedCharacterRegex)) {
      return "Password may only use letters, digits, and common punctuation";
    }

    return null;
  },
  isLessThanOrEqualToDataValue: function isLessThanOrEqualToDataValue(key, message, value, data) {
    if (value <= data.get(key)) {
      return null;
    }

    return message;
  },
  isLessThanDataValue: function isLessThanDataValue(key, message, value, data) {
    if (value < data.get(key)) {
      return null;
    }

    return message;
  },
  isGreaterThanOrEqualToDataValue: function isGreaterThanOrEqualToDataValue(key, message, value, data) {
    if (value >= data.get(key)) {
      return null;
    }

    return message;
  },
  isGreaterThanDataValue: function isGreaterThanDataValue(key, message, value, data) {
    if (value > data.get(key)) {
      return null;
    }

    return message;
  },
  equalsDataValue: function equalsDataValue(key, message, value, data) {
    if (data.get(key) !== value) {
      return message;
    }

    return null;
  },
  equalsDataValueCaseIgnored: function equalsDataValueCaseIgnored(key, message, value, data) {
    if ((0, _toLower.default)(data.get(key)) !== (0, _toLower.default)(value)) {
      return message;
    }

    return null;
  },
  notEqualsDataValue: function notEqualsDataValue(key, message, value, data) {
    if (data.get(key) === value) {
      return message;
    }

    return null;
  },
  is: function is(messageFunction, value, data, fieldName, parentData, form) {
    return messageFunction(value, fieldName, form);
  },
  isLowerCase: function isLowerCase(key, value) {
    if (!(0, _isEmpty.default)(value) && value !== (0, _toLower.default)(value)) {
      return (0, _stringFormat.default)("{0} must be lowercase", key);
    }

    return null;
  },
  hasNoSpaces: function hasNoSpaces(key, value) {
    if ((0, _isNil.default)(value)) {
      return null;
    }

    if (_validator.default.matches(value, /\s/)) {
      return (0, _stringFormat.default)("{0} must not contain spaces", key);
    }

    return null;
  },
  matchesRegExp: function matchesRegExp(customRegExp, errorMessage, value) {
    if (!_validator.default.matches(value, new RegExp(customRegExp))) {
      return errorMessage;
    }

    return null;
  }
}; // Wrap all of the validations so that they are chainable.

exports.defs = defs;

var wrapFunction = function wrapFunction(validationFn, stopFn) {
  return function () {
    var context = (0, _extend.default)({
      __validationContext: true,
      fn: validationFn,
      stop: stopFn,
      args: arguments
    }, defs);

    if (this.__validationContext) {
      this.next = context;
      context.root = this.root;
    } else {
      context.root = context;
    }

    return context;
  };
};

exports.defs = defs = (0, _mapValues.default)(defs, function (v) {
  return wrapFunction(v);
}); // Use this to short-circuit a validation.

defs.conditional = wrapFunction(undefined, function (fn, value, data, fieldName, parentData, form) {
  return !fn(value, fieldName, form);
}); // And add the validate() method that can be called at the end of the chain.
// Returns errors if there are any, and otherwise is undefined.

defs.validate = function (fieldName, form) {
  var rawValue = form.data.get(fieldName);
  var value = (0, _isUndefined.default)(rawValue) || (0, _isNull.default)(rawValue) || (0, _isNaN.default)(rawValue) ? rawValue : rawValue.toString();
  var parentData = (0, _isNull.default)(form.parent) ? {} : form.parent;
  var data = form.data;

  if (!this.__validationContext) {
    throw new Error("Invalid validation chain");
  }

  var errors = [];
  var next = this.root;

  while (next) {
    var args = [].concat(Array.prototype.slice.apply(next.args)).concat([value, data, fieldName, parentData, form]); // Conditionally stop validations.

    if (next.stop) {
      var _next;

      if ((_next = next).stop.apply(_next, _toConsumableArray(args))) {
        return errors.length ? errors : undefined;
      }
    } else {
      var _next2;

      var error = (_next2 = next).fn.apply(_next2, _toConsumableArray(args));

      if (error) {
        return [error];
      }
    }

    next = next.next;
  }

  if (errors.length > 0) {
    return errors;
  }

  return undefined;
};

var _default = defs;
exports.default = _default;