"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

var _forEach = _interopRequireDefault(require("lodash/forEach"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// reformat error keys from server side to match key.array_index.key2 (all dot delimited) keys
function _default(errorObject) {
  (0, _forEach.default)(errorObject, function (errorMessages, key) {
    var newKey = key.split(".") // lowercase the first letter of every string key token
    .map(function (token) {
      return token.charAt(0).toLowerCase() + token.substr(1);
    }).join(".") // replace all array/dictionary key delimiters with a dot
    .replace(/[\[\]\."]+/g, ".");

    if (newKey != key) {
      errorObject[newKey] = errorMessages.slice(); // unset old error key

      delete errorObject[key];
    }
  });
  return errorObject;
}