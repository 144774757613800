"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _numeral = _interopRequireDefault(require("numeral"));

var _isFinite = _interopRequireDefault(require("lodash/isFinite"));

var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));

var _round = _interopRequireDefault(require("lodash/round"));

var _replace = _interopRequireDefault(require("lodash/replace"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var nonNumericalRegex = /[^0-9.]+/gm;
var percentRegEx = /^[-]?[0-9.]+%$/;

var hasPercentSign = function hasPercentSign(v) {
  return v && percentRegEx.test(v);
};

var convertInt = function convertInt(v) {
  var numericValue = parseInt(v, 10);
  return (0, _isFinite.default)(numericValue) ? numericValue : v;
};

var convertFloat = function convertFloat(v) {
  var numericValue = parseFloat(v);
  return (0, _isFinite.default)(numericValue) ? numericValue : v;
};

var convertNumber = function convertNumber(v) {
  var numericValue = (0, _numeral.default)(v).value();
  var useEnteredValue = (0, _isEmpty.default)(v) || !(0, _isFinite.default)(v) && numericValue === 0;
  return useEnteredValue ? v : numericValue;
};

var convertPercent = function convertPercent(v) {
  var percentNumber = (0, _replace.default)(v, "%", "");
  return convertNumber(percentNumber);
};

var convertPercentWithSign = function convertPercentWithSign(v) {
  return hasPercentSign(v) ? convertPercent(v) : v;
};

var convertRate = function convertRate(v) {
  if (!v) {
    return "";
  } else if (nonNumericalRegex.test(v)) {
    return v;
  }

  var convertedValue = convertPercent(v);
  return (0, _isFinite.default)(convertedValue) ? (0, _round.default)(convertedValue, 3) : convertedValue;
};

var convertRateWithPercentSign = function convertRateWithPercentSign(v) {
  if (!v) {
    return "";
  } else if (nonNumericalRegex.test(v) && !hasPercentSign(v)) {
    return v;
  }

  var convertedValue = convertPercentWithSign(v);
  return (0, _isFinite.default)(convertedValue) ? (0, _round.default)(convertedValue, 3) : convertedValue;
};

var _default = {
  out: {
    convertInt: convertInt,
    convertFloat: convertFloat,
    convertNumber: convertNumber,
    convertPercent: convertPercent,
    convertPercentWithSign: convertPercentWithSign,
    convertRate: convertRate,
    convertRateWithPercentSign: convertRateWithPercentSign
  }
};
exports.default = _default;