"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.observableMap = void 0;
// Implements legacy (<= 3.x) observable map methods
var observableMap = {
  keys: function keys(map) {
    return Array.from(map.toJS().keys());
  },
  values: function values(map) {
    return Array.from(map.toJS().values());
  }
};
exports.observableMap = observableMap;