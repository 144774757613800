"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _default = function _default(guid) {
  return guid === "00000000-0000-0000-0000-000000000000";
};

exports.default = _default;