"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _get = _interopRequireDefault(require("lodash/get"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = function _default() {
  return (// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/doNotTrack
    // Edge, Firefox, Chrome
    (0, _get.default)(navigator, "doNotTrack", "1") === "1" // IE, Safari
    && (0, _get.default)(window, "doNotTrack", "1") === "1" // https://globalprivacycontrol.github.io/gpc-spec/
    || navigator.globalPrivacyControl
  );
};

exports.default = _default;