"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.markForFocusLater = markForFocusLater;
exports.returnFocus = returnFocus;
exports.setupScopedFocus = setupScopedFocus;
exports.teardownScopedFocus = teardownScopedFocus;

var _tabbable = _interopRequireDefault(require("./tabbable"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Adapted from https://github.com/rackt/react-modal
var modalElement = null;
var focusLaterElement = null;
var needToFocus = false;

function handleBlur(event) {
  needToFocus = true;
}

function handleFocus(event) {
  if (needToFocus) {
    needToFocus = false; // need to see how jQuery shims document.on("focusin") so we don"t need the
    // setTimeout, firefox doesn't support focusin, if it did, we could focus
    // the the element outisde of a setTimeout. Side-effect of this
    // implementation is that the document.body gets focus, and then we focus
    // our element right after, seems fine.

    setTimeout(function () {
      if (!modalElement || modalElement.contains(document.activeElement)) {
        return;
      }

      var el = (0, _tabbable.default)(modalElement)[0] || modalElement;
      el.focus();
    }, 0);
  }
}

function markForFocusLater() {
  focusLaterElement = document.activeElement;
}

function returnFocus() {
  if (focusLaterElement) {
    try {
      focusLaterElement.focus();
    } catch (e) {
      // Lowered to info because this can happen when a loading spinner is removed from behind a message modal
      console.info("You tried to return focus to " + focusLaterElement + " but it is not in the DOM anymore");
    }
  }

  focusLaterElement = null;
}

function setupScopedFocus(element) {
  modalElement = element;

  if (window.addEventListener) {
    window.addEventListener("blur", handleBlur, false);
    document.addEventListener("focus", handleFocus, true);
  } else {
    window.attachEvent("onBlur", handleBlur);
    document.attachEvent("onFocus", handleFocus);
  }
}

function teardownScopedFocus() {
  modalElement = null;

  if (window.addEventListener) {
    window.removeEventListener("blur", handleBlur);
    document.removeEventListener("focus", handleFocus);
  } else {
    window.detachEvent("onBlur", handleBlur);
    document.detachEvent("onFocus", handleFocus);
  }
}