"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  camelCaseToSnakeCase: function camelCaseToSnakeCase(camelCaseString) {
    return camelCaseString.replace(/[a-z][A-Z]/g, function (match) {
      return match.charAt(0) + "_" + match.charAt(1);
    });
  }
};
exports.default = _default;