"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toUpper = _interopRequireDefault(require("lodash/toUpper"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = {
  isProdIntra: function isProdIntra(session) {
    return session.environmentName && ((0, _toUpper.default)(session.environmentName) === "PRODINTRA" || (0, _toUpper.default)(session.environmentName) === "AWSPROD");
  }
};
exports.default = _default;