"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  UI_NOT_READY: 1,
  UI_READY: 2,
  DATA_REFRESH_NEEDED: 3,
  DATA_LOADING: 4,
  DATA_LOADED: 5
};
exports.default = _default;