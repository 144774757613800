"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/**
 * Wrapper for ga()
 * See https://developers.google.com/analytics/devguides/collection/analyticsjs/events
 */
var _default = function _default(eventCategory, eventAction, eventLabel, eventValue, fieldsObject) {
  if (typeof ga === "function") {
    // TODO Figure out how to do this with GTM dataLayer.
    ga(function () {
      var tracker = ga.getAll()[0];

      if (tracker) {
        tracker.send("event", eventCategory, eventAction, eventLabel, eventValue, fieldsObject);
      }
    });
  }
};

exports.default = _default;