"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _cookie = _interopRequireDefault(require("cookie"));

var _responseHandler = _interopRequireDefault(require("./responseHandler"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Using this module directly is deprecated. Use chainedRequest() or formRequest() instead.
// We require superagent instead of importing it so that it can be replaced by supertest in unit tests.
var internalRequest = require("superagent"); // Add a xsrf token to the header which matches that cookie.


var setCsrfHeader = function setCsrfHeader(req) {
  if (typeof document !== "undefined") {
    var cookies = _cookie.default.parse(document.cookie);

    if (cookies.csrf) {
      return req.set("X-CSRF-TOKEN", cookies.csrf);
    }
  }

  return req;
};

var addCommonHeaders = function addCommonHeaders(req) {
  // superagent doesn't set X-Requested-With header
  return req.set("X-Requested-With", "XMLHttpRequest");
};

var processRequest = function processRequest(req) {
  return setCsrfHeader(addCommonHeaders(req));
}; // Adds a .respond() method to the superagent chain which starts a responseHandler chain.


var createRespondMethod = function createRespondMethod(wrapper, req) {
  // We only want respond() to be added if we are working in request(dispatcher) mode.
  if (wrapper.dispatcher) {
    req.respond = function () {
      return (0, _responseHandler.default)(req, wrapper);
    };
  }
};

var defs = {
  get: function get() {
    var _internalRequest;

    var req = (_internalRequest = internalRequest).get.apply(_internalRequest, arguments);

    createRespondMethod(this, req);
    return processRequest(req);
  },
  post: function post() {
    var _internalRequest2;

    var req = (_internalRequest2 = internalRequest).post.apply(_internalRequest2, arguments);

    createRespondMethod(this, req);
    return processRequest(req);
  },
  del: function del() {
    var _internalRequest3;

    var req = (_internalRequest3 = internalRequest).del.apply(_internalRequest3, arguments);

    createRespondMethod(this, req);
    return processRequest(req);
  },
  __abort: function __abort(req) {
    req.abort();
    this.aborted = true;
  },
  // For testing purposes, change the internal request mechanism.
  __setInternalRequest: function __setInternalRequest(req) {
    internalRequest = req;
  }
};
var _default = defs;
exports.default = _default;