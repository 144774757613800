"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = responseHandler;

var _clientErrorLogger = _interopRequireDefault(require("./clientErrorLogger"));

var _standardizeErrorKeys = _interopRequireDefault(require("./standardizeErrorKeys"));

var _sessionManager = _interopRequireDefault(require("./sessionManager"));

var _extend = _interopRequireDefault(require("lodash/extend"));

var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// These are the actual chainable responses that can be used after calling request(dispatcher).respond()
var defs = {
  // Checks for http errors *and* makes sure that res.body.success is set to true
  onError: function onError(err, res, next, callback) {
    var self = this;
    defs.onHttpError().fn.call(this, err, res, function () {
      if (res.body && res.body.success) {
        return next();
      } else {
        if (self.showingLoadSpinner) {
          self.dispatcher.emit("HIDE_LOAD_SPINNER");
        }

        return callback && callback(err, res, next);
      }
    }, // can't just pass callback directly, because then the above next
    // handler will get called if there is an http error, rather than the
    // true "next" that was passed into onError
    function () {
      callback && callback(err, res, next);
    });
  },
  onHttpError: function onHttpError(err, res, next, callback) {
    // api request is unauthorized, session must be lost...
    if (res && res.unauthorized) {
      // TODO not set in Mobx land, will default to /login
      var loginUrl = _sessionManager.default.getLoginPath();

      if (window && window.location.toString().indexOf(loginUrl) == -1) {
        // prevent redirect loop
        window.location.assign("/logout?redirectUrl=" + loginUrl + "&errorCode=4");
        return;
      }
    } // log any http errors and let the user do their own callback too


    if (err || res && res.status != 200) {
      if (err && err.message) {
        _clientErrorLogger.default.notify(err.message, "error");
      } else {
        _clientErrorLogger.default.notify("Received ".concat(res.status, " from ").concat(res.url), "error");
      }

      if (this.showingLoadSpinner) {
        this.dispatcher.emit("HIDE_LOAD_SPINNER");
      }

      callback && callback(err, res, next);
      return;
    }

    next();
  },
  // check for standard validation error response
  onFormError: function onFormError(err, res, next, formPath, callback) {
    if (!err && res.body && !res.body.success) {
      if (!(0, _isEmpty.default)(res.body.errors)) {
        var errors = res.body.errors;
        errors = (0, _standardizeErrorKeys.default)(errors);
        this.dispatcher.emit("SET_SERVER_ERRORS", {
          errors: errors,
          formPath: formPath
        });

        if (this.showingLoadSpinner) {
          this.dispatcher.emit("HIDE_LOAD_SPINNER");
        }

        callback && callback(err, res, next);
        return;
      }
    }

    next();
  },
  // check for a standard redirect response
  onRedirect: function onRedirect(err, res, next) {
    if (!err && res && res.body && res.body.redirectUrl) {
      if (window.location.pathname + window.location.hash === res.body.redirectUrl) {
        window.location.reload(true);
      } else {
        window.location.assign(res.body.redirectUrl);
      }

      return;
    }

    next();
  }
};

var wrapFunction = function wrapFunction(responseFn) {
  return function () {
    var context = (0, _extend.default)({
      __responseContext: true,
      fn: responseFn,
      args: arguments
    }, defs);
    this.next = context;
    context.root = this.root;
    return context;
  };
}; // Wrap all of the response functions so that they are chainable.


Object.keys(defs).forEach(function (key) {
  return defs[key] = wrapFunction(defs[key]);
}); // Holy toledo, recursion! This just works its way down the chained functions.

var chain = function chain(next, err, res) {
  if (next) {
    var self = this; // The anonymous function is for calling the next function in the chain, which is used optionally so that
    // handlers can choose whether or not to short-circuit.

    var internalArgs = [err, res, function () {
      return chain.call(self, next.next, err, res);
    }]; // Get the user-defined args.

    var nextArgs = Array.prototype.slice.apply(next.args || []);
    next.fn.apply(this, internalArgs.concat(nextArgs));
  }
}; // This must be the last method in a request(dispatcher).respond() chain, because it is responsible
// for telling superagent to actually make the AJAX request.


defs.handle = function (callback) {
  if (!this.__responseContext) {
    throw new Error("Invalid response chain");
  }

  var self = this;
  var root = this.root;

  if (root.aborted) {
    if (root.showingLoadSpinner) {
      root.dispatcher.emit("HIDE_LOAD_SPINNER");
    } // By returning before calling req.end(), the AJAX request will never be made.


    return;
  }

  root.req.end(function (err, res) {
    // This method will only get called if all of the chained methods succeed
    var responseFn = function responseFn() {
      if (root.showingLoadSpinner) {
        root.dispatcher.emit("HIDE_LOAD_SPINNER");
      }

      callback && callback(err, res);
    };

    var context = (0, _extend.default)({
      __responseContext: true,
      fn: responseFn,
      callback: callback
    }, defs);
    self.next = context;
    root.fn(err, res);
  });
}; // Returned by request(dispatcher).respond() -- this is the root of the response chain.


function responseHandler(req, wrapper) {
  var context = (0, _extend.default)({
    __responseContext: true,
    req: req
  }, defs);
  context.root = context;
  context.aborted = wrapper.aborted;
  context.showingLoadSpinner = wrapper.showingLoadSpinner;
  context.dispatcher = wrapper.dispatcher;

  context.fn = function (err, res) {
    chain.call({
      dispatcher: wrapper.dispatcher,
      showingLoadSpinner: wrapper.showingLoadSpinner
    }, context.next, err, res);
  };

  return context;
}