"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _doNotTrack = _interopRequireDefault(require("../../util/doNotTrack"));

var _reactDeviceDetect = require("react-device-detect");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = function _default() {
  if (!(0, _doNotTrack.default)() && !_reactDeviceDetect.isIE) {
    var mouseflow = document.createElement("script");
    mouseflow.type = "text/javascript";
    mouseflow.async = true;
    mouseflow.src = "//cdn.mouseflow.com/projects/9daadec9-1e06-4299-8e4e-ce2065ed979c.js";
    document.getElementsByTagName("head")[0].appendChild(mouseflow);
  }
};

exports.default = _default;