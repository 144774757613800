"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

var _requestWrapper = _interopRequireDefault(require("./requestWrapper"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _default(dispatcher) {
  return new _requestWrapper.default(dispatcher);
}