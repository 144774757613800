"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  pushState: function pushState(stateData, title, url) {
    if (typeof history !== "undefined" && history.pushState) {
      if (url) {
        // IE is dumb and if url is undefined it'll actually change the url to undefined                
        history.pushState(stateData, title, url);
      } else {
        history.pushState(stateData, title);
      }
    }
  },
  replaceState: function replaceState(stateData, title, url) {
    if (typeof history !== "undefined" && history.replaceState) {
      if (url) {
        // IE is dumb and if url is undefined it'll actually change the url to undefined                
        history.replaceState(stateData, title, url);
      } else {
        history.replaceState(stateData, title);
      }
    }
  },
  addPopStateListener: function addPopStateListener(listener) {
    if (typeof history !== "undefined" && history.pushState) {
      window.addEventListener("popstate", listener);
    }
  },
  removePopStateListener: function removePopStateListener(listener) {
    if (typeof history !== "undefined" && history.pushState) {
      window.removeEventListener("popstate", listener);
    }
  },
  back: function back() {
    if (typeof history !== "undefined" && history.back) {
      history.back();
    }
  }
};
exports.default = _default;