"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var scrollOffset = 0;
var _default = {
  lock: function lock(lockScrollBar) {
    scrollOffset = window.scrollY;

    if (lockScrollBar) {
      document.body.classList.add("noscroll");
    }

    document.body.classList.add("locked");

    if (getComputedStyle(document.body).position === "fixed") {
      document.body.style.top = "-".concat(scrollOffset, "px");
    }
  },
  unlock: function unlock() {
    if (getComputedStyle(document.body).position === "fixed") {
      window.scrollTo(0, scrollOffset);
      document.body.style.top = "";
    }

    document.body.classList.remove("noscroll");
    document.body.classList.remove("locked");
  }
};
exports.default = _default;