"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

var _tabbable = _interopRequireDefault(require("./tabbable"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Adapted from https://github.com/rackt/react-modal
function _default(node, e) {
  var tabbable = (0, _tabbable.default)(node);
  var finalTabbable = tabbable[e.shiftKey ? 0 : tabbable.length - 1];
  var leavingFinalTabbable = finalTabbable === document.activeElement || // handle immediate shift+tab after opening with mouse
  node === document.activeElement;

  if (!leavingFinalTabbable) {
    return;
  }

  e.preventDefault();
  var target = tabbable[e.shiftKey ? tabbable.length - 1 : 0];

  if (target) {
    target.focus();
  }
}